import React from 'react';
import AnimatedDialog from '@/components/app/AnimatedDialog';
import { useMobileCheckHook } from '@/hooks/celebration';
import { mobileModalCSS } from '../ReusableComponents';
import RenderPayment from './RenderPayment';

export default function PaymentPageVideoGuestBook({ onDismiss, isOpen = false, planId }) {
  const isFromMobile = useMobileCheckHook();

  return (
    <AnimatedDialog
      isOpen={isOpen}
      contentProps={{ width: '90vw', maxWidth: planId ? 880 : 1022 }}
      contentCSS={isFromMobile && !planId && mobileModalCSS()}
      isFullScreenModal={!planId}
      onDismiss={onDismiss}
    >
      <RenderPayment parentPlanId={planId} />
    </AnimatedDialog>
  );
}
