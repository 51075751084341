import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import { Flex, Box, Button, Text } from 'rebass';
import {
  PaymentRequestButtonElement,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useTheme } from 'emotion-theming';
import { FiXOctagon } from 'react-icons/fi';
import { useRouter } from 'next/router';
import store from 'store';
import { useEntity } from '@/lib/entities';
import { ReactComponent as PaymentMethodsDesktop } from '@/components/app/Svgs/PaymentMethodsDesktop.svg';
import { ax } from '@/lib/api';
import { useCurrentBuzzCodeAffiliate } from '@/components/BuzzCodeBox';
import { useCurrentMember } from '@/hooks/member';
import useSendToGTM from '@/hooks/googleTagManager';
import { useCurrentMemberTrackingId } from '@/hooks/auth';
import { checkIfIdExist, sendTrackingEvent } from '@/lib/helper';
import Banner from './app/Banner';
import StripeSecureDesktop from './app/Svgs/StripeSecureDesktop';
import { Spinner } from './app/Loading';

function CheckoutForm({
  id,
  buttonStyles = {},
  buttonTitle = 'Make Payment',
  onSuccess,
  renderRightText,
  parentPlanId,
}) {
  const {
    query: { id: celebrationId, redirect, memberPartnerDiscountId },
    push,
  } = useRouter();
  const { mutate: revalidateCelebration } = useSWR(
    checkIfIdExist(celebrationId) ? `/v3/celebrations/${celebrationId}?shallow=true` : null
  );
  const [loading, setLoading] = useState(true);
  const [postalCode, setPostalCode] = useState('');

  const currentBuzzCodeAffiliate = useCurrentBuzzCodeAffiliate();
  const code = currentBuzzCodeAffiliate?.['referral-code'];
  const [status, setStatus] = useState({ type: '', message: '' });
  const currentMember = useCurrentMember();

  const email = currentMember?.email;
  const trackingId = useCurrentMemberTrackingId();
  const sendToGTM = useSendToGTM();

  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();

  const plan = useEntity({ id, type: 'pricing-plans' });
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (stripe && elements) setLoading(false);
  }, [stripe, elements]);

  async function onSuccessCallback(clientSecret) {
    const paymentBody = {
      pricing_plan_id: plan.id,
      gift: false,
      email,
    };
    if (memberPartnerDiscountId) paymentBody.member_partner_discount_id = memberPartnerDiscountId;
    if (document.getElementsByName('coupon') && document.getElementsByName('coupon').length > 0)
      paymentBody.coupon = document.getElementsByName('coupon')[0].value;

    if (document.getElementsByName('referral') && document.getElementsByName('referral').length > 0)
      paymentBody.referral = document.getElementsByName('referral')[0].value;

    if (memberPartnerDiscountId) paymentBody.member_partner_discount_id = memberPartnerDiscountId;

    if (store.get('iufi')) paymentBody.source = 'impact';

    const paymentData = await ax().patch(`/v3/payment_intents/${clientSecret}`, paymentBody);

    if (paymentData?.data?.data?.attributes?.['commerce-transaction-id']) {
      const {
        'commerce-transaction-id': transactionId,
        'currency-code': currencyCode,
        'order-promo-code': orderPromoCode,
        'order-discount': orderDiscount,
        'sub-total': subTotal,
        'customer-status': customerStatus,
        category,
        sku,
        quantity,
        name,
      } = paymentData.data.data.attributes;
      const gtmObject = {
        event: 'transaction',
        transactionId,
        transactionTotal: plan['price-in-dollars'],
        transactionProducts: [
          {
            name: plan.name,
            sku: plan['stripe-identifier'],
            price: plan['price-in-dollars'],
            quantity: 1,
          },
        ],
        User_Id: trackingId,
      };
      if (store.get('iufi') && window.ire) {
        window.ire(
          'trackConversion',
          42435,
          {
            orderId: transactionId,
            customerId: currentMember?.id,
            customerEmail: currentMember?.['email-sha1'],
            currencyCode,
            orderPromoCode,
            orderDiscount,
            customerStatus,
            items: [
              {
                subTotal,
                category,
                sku,
                quantity,
                name,
              },
            ],
          },
          {
            verifySiteDefinitionMatch: true,
          }
        );
      }

      if (currentBuzzCodeAffiliate?.['referral-code']) {
        gtmObject.buzzCodeValue = currentBuzzCodeAffiliate?.['referral-code'];
      }
      sendToGTM(gtmObject);
    }
    setStatus({ type: '', message: '' });
    revalidateCelebration();
    if (onSuccess) onSuccess();
    else {
      setStatus({ type: 'success', message: 'success' });
      const url = `/dashboard/${celebrationId}/payment/${id}/success${
        redirect ? `?redirect=${redirect}` : ''
      }`;
      sendTrackingEvent(`member_visit`, {
        context: {
          url_text: buttonTitle,
          url,
        },
      });
      push(url);
    }
  }

  useEffect(() => {
    if (!stripe || !elements || !plan || !plan?.stripe) return;

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: plan?.stripe?.currency,
      total: {
        label: plan?.['stripe-identifier'],
        amount: plan?.stripe?.['price-in-cents'],
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
      }
    });

    pr.on('paymentmethod', async (ev) => {
      const { data, status: intentStatus } = await ax().post(`/v3/payment_intents`, {
        pricing_plan_id: plan.id,
        postal_code: ev?.paymentMethod?.billing_details?.address?.postal_code,
      });

      if ((intentStatus === 200 || intentStatus === 201) && data?.payment_intent_client_secret) {
        const clientSecret = data?.payment_intent_client_secret;

        const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

        if (confirmError) {
          setStatus({ type: 'error', message: 'Please try again' });
          ev.complete('fail');
        } else {
          ev.complete('success');

          if (paymentIntent.status === 'requires_action') {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              setStatus({ type: 'error', message: 'Please try again with another card' });
            } else {
              onSuccessCallback(paymentIntent?.id);
            }
          } else {
            onSuccessCallback(paymentIntent?.id);
          }
        }
      } else {
        setStatus({ type: 'error', message: data?.error });
        ev.complete('fail');
      }
    });
  }, [stripe, elements, plan]);

  if (
    typeof window !== 'undefined' &&
    document.getElementById('the-payments-form') &&
    window.Rewardful
  ) {
    const signupForm = document.getElementById('the-payments-form');
    window.Rewardful.Forms.add(signupForm);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setStatus({ type: 'loading', message: 'loading' });
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (code) {
      // await POST buzz code
      await ax()
        .post(`/v3/member_affiliates/${code}`)
        .catch((err) =>
          store.set(
            'sentryError',
            `${err.response?.data?.errors[0]?.detail} /CheckoutForm Component error occured while applying memeber_affiliate code`
          )
        );
    }

    const { data, status: intentStatus } = await ax().post(`/v3/payment_intents`, {
      pricing_plan_id: plan.id,
      postal_code: postalCode,
    });

    if (intentStatus !== 200 && intentStatus !== 201) {
      setStatus({ type: 'error', message: data?.error });
      return;
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      data?.payment_intent_client_secret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            email,
          },
        },
      }
    );

    if (error) {
      setStatus({ type: 'error', message: 'Please try again with another card' });
      return;
    }

    onSuccessCallback(paymentIntent?.id);
  };

  const isLoading = status.type === 'loading';
  return (
    <Box id="the-payments-form" as="form" onSubmit={handleSubmit} data-rewardful="true">
      {paymentRequest && (
        <Box
          sx={{
            ':after': {
              display: 'block',
              width: '100%',
              height: 2,
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              margin: '10px 0px',
              content: '" "',
            },
          }}
        >
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </Box>
      )}
      {loading ? (
        <Flex m="auto" justifyContent="center">
          <Spinner style={{ width: 70, height: 70 }} />
        </Flex>
      ) : (
        <Box flex={1} maxWidth={540} m="auto">
          <Text
            textAlign="center"
            color="#505050CC"
            fontWeight={400}
            fontSize="18px"
            lineHeight="140%"
          >
            Please enter your payment info.
          </Text>

          <Box
            mt={3}
            p={1}
            maxWidth={540}
            sx={{
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor: 'border',
              border: '1px solid #B8B6C7',
              borderRadius: '8px',
            }}
          >
            <CardElement
              onChange={(change) => {
                if (change.complete) {
                  if (change?.value?.postalCode) {
                    setPostalCode(change?.value?.postalCode);
                  }
                }

                if (status.error && !change.error) {
                  setStatus({ type: '', message: '' });
                }
              }}
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: theme.colors.body,
                    '::placeholder': {
                      color: theme.colors.greys[4],
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          </Box>
          <Box mt={1}>
            <Text
              className="dm-sans"
              color="#B8B6C7"
              fontSize="16px"
              lineHeight="16px"
              fontWeight={500}
              mb={1}
            >
              Payment Options
            </Text>
            <Flex flexDirection={['column', 'row']} alignItems="center" p={parentPlanId ? 2 : 0}>
              <Box mr={[0, 1]}>
                <StripeSecureDesktop />
              </Box>
              <Box mb={[2, 0]}>
                <PaymentMethodsDesktop />
              </Box>
            </Flex>
          </Box>

          {/* <BuzzCodeBox
        value={code}
        disabled={!!currentBuzzCodeAffiliate}
        onChange={setCode}
         /> */}
          {status.type === 'error' && (
            <Banner
              icon={FiXOctagon}
              color="pinks.5"
              bg="pinks.1"
              p={3}
              heading="Payment Failed"
              text={status.message}
              mb={4}
              size="small"
            />
          )}
          {!renderRightText && (
            <Flex
              justifyContent={['center', 'flex-end']}
              sx={{
                borderTop: ['1px solid #B8B6C7', 0],
                pt: 2,
              }}
            >
              <Button
                type="submit"
                disabled={!stripe || isLoading}
                sx={buttonStyles}
                variant="special"
                p={2}
                className={isLoading && 'pulse loading'}
              >
                {isLoading ? 'Processing' : buttonTitle}
              </Button>
            </Flex>
          )}
        </Box>
      )}
      {renderRightText && (
        <Flex
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-between"
          mt={[0, 4]}
          pt={[2, 0]}
          sx={{
            borderTop: ['1px solid #B8B6C7', '1px solid #B8B6C7', 0],
          }}
          maxWidth={922}
        >
          <Button
            type="submit"
            variant="special"
            disabled={!stripe || isLoading}
            sx={buttonStyles}
            p={2}
            my={['10px', '10px', '0px']}
            height={50}
            className={isLoading && 'pulse loading'}
          >
            {isLoading ? 'Processing' : buttonTitle}
          </Button>
          {renderRightText}
        </Flex>
      )}
    </Box>
  );
}

CheckoutForm.propTypes = {
  id: PropTypes.string.isRequired,
};

export default React.memo(CheckoutForm);
