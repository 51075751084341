import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Heading, Text, Box, Button } from 'rebass';
import { Input, Label } from '@rebass/forms';
import useSWR from 'swr';
import { DateTime } from 'luxon';
import Grid from '@/components/app/Grid';
import { useCurrentMember } from '@/hooks/member';
import { useRelatedEntities, useEntity } from '@/lib/entities';
import { useIsCurrentUserAuthenticated } from '@/hooks/auth';
// eslint-disable-next-line import/no-cycle
import BuzzCodeBanner from './CelebrationDashboard/BuzzCodeBanner';

export const useCurrentBuzzCode = () => {
  const member = useCurrentMember();
  // const memberAffiliates = useEntities({ type: 'member-affiliates' });
  const buzzCodes = useRelatedEntities({
    type: 'members',
    id: member?.id,
    relationship: 'member-affiliates',
  });

  if (!member) return undefined;
  if (!member['member-affiliates']) return undefined;
  if (!buzzCodes) return undefined;

  const mostRecentAffiliate = buzzCodes
    .filter((el) => el?.state === 'active')
    .reduce((prev, el) => {
      if (!prev) return el;
      const prevDate = DateTime.fromISO(prev['updated-at']);
      const elDate = DateTime.fromISO(el['updated-at']);
      if (elDate > prevDate) return el;
      return prev;
    }, undefined);

  return mostRecentAffiliate;
};

export const useCurrentBuzzCodeAffiliate = () => {
  const currentBuzzCode = useCurrentBuzzCode();
  return useEntity(currentBuzzCode?.affiliate);
};

function BuzzCodePreview({ code, onSuccess, isAuthenticated }) {
  const { data, error, isValidating } = useSWR(
    code ? `/v3${isAuthenticated ? '' : '/public'}/affiliates/${code}` : null
  );

  useEffect(() => {
    if (data && onSuccess) {
      onSuccess();
    }
  }, [data]);

  if (isValidating) {
    return (
      <Box bg="greys.1" p={2} mb={2}>
        <Heading variant="headings.h5" color="greys.6">
          Loading...
        </Heading>
      </Box>
    );
  }
  if (error) {
    return (
      <Box bg="pinks.1" p={2} mb={2}>
        <Heading variant="headings.h5" color="pinks.4">
          {code} not found
        </Heading>
      </Box>
    );
  }

  if (data) {
    return (
      <Box bg="successBg" p={2} mb={2}>
        <Heading variant="headings.h5" color="success">
          Code Applied:
        </Heading>
        <Text color="success">{data.data.data.attributes?.humanized}</Text>
      </Box>
    );
  }
  return <BuzzCodeBanner />;
}

function BuzzCodeBox({ onChange, value, applyCode, isFromMobile, disabled, onSuccess }) {
  const [triggerBuzzCode, setTriggerBuzzCode] = useState(true);
  const [previewCode, setPreviewCode] = useState(applyCode ? value : null);
  const isAuthenticated = useIsCurrentUserAuthenticated();
  useEffect(() => {
    if (applyCode) {
      setTriggerBuzzCode(false);
      setTimeout(() => setTriggerBuzzCode(true), 4000);
    }
  }, [applyCode]);

  return (
    <Box pb={2}>
      <Label pb={1}>Buzz Code</Label>
      <Grid gridTemplateColumns="1fr max-content" gridGap={isFromMobile ? 1 : 3} pb={2}>
        <Input
          value={value}
          type="input"
          onChange={(e) => {
            // setCode(e.target.value);
            onChange(e.target.value);
          }}
          placeholder="Enter your Buzz Code"
          disabled={disabled}
        />
        <Button
          variant="muted"
          type="button"
          disabled={!value}
          onClick={() => setPreviewCode(value)}
        >
          Apply
        </Button>
      </Grid>
      {triggerBuzzCode ? (
        <BuzzCodePreview
          code={previewCode}
          onSuccess={onSuccess}
          isAuthenticated={isAuthenticated}
        />
      ) : (
        <Box bg="greys.1" p={2} mb={2}>
          <Heading variant="headings.h5" color="greys.6">
            Loading...
          </Heading>
        </Box>
      )}
    </Box>
  );
}

BuzzCodeBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  applyCode: PropTypes.bool,
  value: PropTypes.string,
};
BuzzCodeBox.defaultProps = { value: null, disabled: false, applyCode: false };

export default BuzzCodeBox;
