import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass';

function Badge({ children, bg, color, sx }) {
  return (
    <Flex
      height={24}
      sx={{ borderRadius: '12px', ...sx }}
      bg={bg}
      alignItems="center"
      justifyContent="center"
      px={12}
    >
      <Text fontSize={12} color={color} fontWeight={600}>
        {children}
      </Text>
    </Flex>
  );
}

Badge.propTypes = {
  children: PropTypes.string.isRequired,
  bg: PropTypes.string,
  color: PropTypes.string,
};
Badge.defaultProps = {
  bg: 'lightBody',
  color: 'white',
};

export default Badge;
