import { useContext } from 'react';
import store from 'store';
import { AuthContext } from '@/components/Auth';
// eslint-disable-next-line import/no-cycle
import { useCelebrationInRoute } from './celebration';
import { useGetCurrentMember } from './api';

export const useCurrentMemberId = () => {
  const [{ memberId }] = useContext(AuthContext);
  return memberId;
};

export const useCurrentMemberTrackingId = () => {
  const { data } = useGetCurrentMember();
  const attributes = data?.data?.data?.attributes;
  if (attributes) {
    const { 'tracking-identifier': trackingIdentifier } = attributes;
    return trackingIdentifier;
  }
  return null;
};

export const useIsCurrentMemberCelebrationOwner = () => {
  const currentMemberId = useCurrentMemberId();
  const celebrationInRoute = useCelebrationInRoute();
  const celebrationOwnerId = celebrationInRoute?.creator?.id;
  return currentMemberId === celebrationOwnerId;
};

export const useIsCurrentUserHasUsernamePassword = () => {
  const { data } = useGetCurrentMember();
  const attributes = data?.data?.data?.attributes;
  if (attributes) {
    const { 'has-username-password-identifier': hasUsernamePasswordIdentifier } = attributes;

    return hasUsernamePasswordIdentifier;
  }
  return null;
};

export const useIsCurrentUserAuthenticated = () => {
  const { data } = useGetCurrentMember();
  const attributes = data?.data?.data?.attributes;
  if (attributes) {
    const { 'has-username-password-identifier': hasUsernamePasswordIdentifier } = attributes;

    return !!store.get('token') && hasUsernamePasswordIdentifier;
  }
  return !!store.get('token');
};
