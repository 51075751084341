import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Box } from 'rebass';
import Grid from '@/components/app/Grid';
import { useMobileCheckHook } from '@/hooks/celebration';

function OnboardSection({
  title,
  support,
  children,
  isNotActiveRoute,
  isFromVideoGuestBook,
  step,
  subTitle,
  isFromIdeas,
  ...props
}) {
  const isFromMobile = useMobileCheckHook();

  return (
    <Grid
      gridTemplateColumns={isNotActiveRoute ? ['1fr', '1fr 2fr'] : ['1fr']}
      gridColumnGap={7}
      gridRowGap={3}
      pb={8}
      maxWidth={922}
      {...props}
    >
      <Box mt={5}>
        {step}
        {subTitle}
        <Heading
          className={isFromVideoGuestBook ? 'font-alta' : 'gradientHeading'}
          variant={isFromVideoGuestBook ? 'headings.h1v1' : 'headings.h1'}
          textAlign={isFromVideoGuestBook ? 'center' : 'left'}
          pb={[2]}
          fontSize={[4, 5]}
          width={!isFromMobile && !isNotActiveRoute && 'max-content'}
        >
          {title}
        </Heading>
        {support}
      </Box>
      <Box
        bg={isFromVideoGuestBook && !isFromIdeas && 'white'}
        sx={{ borderRadius: isFromVideoGuestBook && '8px' }}
      >
        {children}
      </Box>
    </Grid>
  );
}
OnboardSection.propTypes = {
  title: PropTypes.string,
  support: PropTypes.node,
  children: PropTypes.node.isRequired,
};
OnboardSection.defaultProps = { title: '', support: null };

export default OnboardSection;
