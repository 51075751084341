import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { Box } from 'rebass';

function Loading({ local }) {
  if (local) {
    return (
      <LocalWrap>
        <Spinner />
      </LocalWrap>
    );
  }

  return (
    <ModalWrap>
      <Spinner />
    </ModalWrap>
  );
}

Loading.propTypes = {
  local: PropTypes.bool,
};

Loading.defaultProps = {
  local: false,
};

export const ModalWrap = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
`;

const LocalWrap = styled.div`
  z-index: 10;
  display: flex;
`;

export const Spinner = styled.div`
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 2.1em solid rgba(122, 105, 191, 0.2);
  border-right: 2.1em solid rgba(122, 105, 191, 0.2);
  border-bottom: 2.1em solid rgba(122, 105, 191, 0.2);
  border-left: 2.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  margin: auto;
  border-radius: 50%;
  width: 88px;
  height: 88px;
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const LoadingBg = styled(Box)`
  background-color: rgba(0, 0, 0, 0.7);
  animation-name: color;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  @keyframes color {
    0% {
      background-color: rgba(0, 0, 0, 0.7);
    }
    50% {
      background-color: rgba(0, 0, 0, 0.9);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
`;

export default Loading;
