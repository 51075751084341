import { useMemo, useCallback } from 'react';
import { useRouter } from 'next/router';
import store from 'store';
import useSWR, { mutate } from 'swr';
import { useWindowSize } from 'react-use';
import { fetcher, ax } from '@/lib/api';
import { useGetCelebrationOccasions } from '@/hooks/api';
import { useCelebration, useMobileCheckHook } from '@/hooks/celebration';
import { useCurrentMember } from '@/hooks/member';
import { VIDEO_GUEST_BOOK } from '@/lib/constants';
import { useEntities } from '@/lib/entities';

export const useOccasionsOptions = () => {
  const { data } = useGetCelebrationOccasions();

  return useMemo(() => {
    if (!data) return [];
    return data.data.data.map((oc) => ({
      label: oc.attributes.description,
      value: oc.id,
      other: oc.attributes.other,
    }));
  }, [data]);
};

export const useHeightAndWidthOfRecorder = () => {
  const { width, height } = useWindowSize();
  const isFromMobile = useMobileCheckHook();

  return useMemo(() => {
    if (isFromMobile || width < 640) {
      return {
        width,
        height,
      };
    }

    if (width < 1025) {
      return {
        width: '640px',
        height: '360px',
      };
    }

    return {
      width: '800px',
      height: '450px',
    };
  }, [width, height, isFromMobile]);
};

// function checkIfDirectFromVGB(directFromVGB, redirectUrl) {
//   if (!directFromVGB) redirectUrl();
//   return null;
// }

export const useSubmitCelebration = () => {
  const { push } = useRouter();
  return useCallback(
    async (attributes, celebrationId) => {
      if (celebrationId) {
        const { data } = await ax().patch(`/v3/celebrations/${celebrationId}`, {
          data: { attributes },
        });
        if (data.data.id) mutate(`/v3/celebrations/${data.data.id}`, { data });
        return { id: data.data.id };

        // if (isFromVideoGuestBook)
        //   checkIfDirectFromVGB(directFromVGB, () => push(`/dashboard/${data.data.id}/poster`));
      }
      const { data } = await ax().post(`/v3/celebrations`, { data: { attributes } });
      if (data.data.id) mutate(`/v3/celebrations/${data.data.id}`, { data });
      store.set('new-celebration-id', data.data.id);
      return { id: data.data.id };
      // if (attributes['celebration-template-id'])
      //   push(`/new/${data.data.id}/${route}`);
      // else
      // if (isFromVideoGuestBook) {
      //   checkIfDirectFromVGB(directFromVGB, () =>
      //     push(`/dashboard/${data.data.id}/poster?isFromNew=true`)
      //   );
      // } else if (route === 'poster') push(`/dashboard/${data.data.id}/poster?isFromNew=true`);
      // else push(`/new/${data.data.id}/${route}`);
    },
    [push]
  );
};

export const useCelebrationTemplate = () => {
  const { data } = useSWR('/v3/my/celebration_templates', fetcher, { revalidateOnFocus: false });
  const member = useCurrentMember();
  return useMemo(() => {
    if (!data) return [];
    const templates = data.data.data.map((oc) => ({
      ...oc.attributes,
      label: oc.attributes.name,
      kind: oc.attributes.kind,
      category: oc.attributes.category,
      value: oc.id,
      selected: !!oc.relationships.member.data,
      isCreatedByMe: !!oc.relationships.member.data?.id === !!member?.id,
    }));
    templates.push({
      label: 'Custom',
      kind: 'question_and_answer',
      value: null,
      inputValue: null,
      selected: false,
      isCreatedByMe: false,
    });
    return templates;
  }, [data, member]);
};

export const useGetSelectedTemplate = (celebrationId) => {
  const {
    query: { id },
  } = useRouter();
  const templates = useEntities({ type: 'celebration-templates' });
  const celebration = useCelebration(celebrationId || id);
  return templates?.[celebration?.['celebration-template']?.id];
};

export const useCheckIfQnAIdea = (id) => {
  const template = useGetSelectedTemplate(id);
  return template ? template?.kind === 'question_and_answer' : null;
};

export const useCheckIfVideoGuestBook = () => {
  const template = useGetSelectedTemplate();
  return template ? template.kind === VIDEO_GUEST_BOOK : null;
};

export const useCheckIfNormalIdea = () => {
  const template = useGetSelectedTemplate();
  return template?.kind === 'normal';
};
