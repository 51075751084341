import React, { useState, useEffect } from 'react';
import { Box, Image, Flex, Text, Button } from 'rebass';
// import { Switch } from '@rebass/forms';
import { useRouter } from 'next/router';
import { useWindowSize } from 'react-use';
import store from 'store';
import {
  calculateLetterSpacing,
  extractDollarValueFromString,
  sendTrackingEvent,
} from '@/lib/helper';
import Reviews from '@/public/Images/Paywal/Reviews.png';
import { PAYWALL_HELP, VIDEO_GUEST_BOOK } from '@/lib/constants';
import { useMobileCheckHook } from '@/hooks/celebration';
import { postPrivateEventDetails } from '@/lib/api';
import ConfettiWrapper from '../ConfettiWrapper';
import AnimatedDialog from '../app/AnimatedDialog';
import { cssHandling } from '../Printables/ReusableComponents';

function WhatsIncluded({ id }) {
  const [iframeSrc, setIframeSrc] = useState(null);
  const [showLearnMore, setShowLearnMore] = useState(false);
  const isFromMobile = useMobileCheckHook();

  useEffect(() => {
    const fetchWebsiteContent = async () => {
      try {
        const response = await fetch(`/api/renderpage?url=${PAYWALL_HELP}`); // Use your proxy route
        if (response.ok) {
          const websiteContent = await response.text();
          setIframeSrc(`data:text/html,${encodeURIComponent(websiteContent)}`);
        }
      } catch (error) {
        console.error('Error fetching website content:', error);
      }
    };

    fetchWebsiteContent();
  }, []);

  return (
    <>
      <AnimatedDialog
        isOpen={showLearnMore}
        contentProps={{ width: '100vw', maxWidth: 922, mt: 2 }}
        contentCSS={isFromMobile && cssHandling()}
        onDismiss={() => setShowLearnMore(false)}
      >
        <iframe
          src={iframeSrc}
          title="Embedded Website"
          width="100%"
          height="800"
          style={{ border: 'none', marginTop: '8px' }}
        />
      </AnimatedDialog>
      <Text
        as="span"
        onClick={() => {
          postPrivateEventDetails('celebration_tapped_whats_included', {
            'subject-type': 'Celebration',
            'subject-id': id,
          });
          setShowLearnMore(true);
        }}
        ml={1}
        sx={{
          textDecorationLine: 'underline',
          color: '#fff',
          fontSize: '20px',
          fontWeight: 700,
          lineHeight: '130%',
          letterSpacing: '-0.44px',
          cursor: 'pointer',
        }}
      >
        What’s included?
      </Text>
    </>
  );
}

function PriceBox({ id, attributes, onClick, theme, onDismiss, isFromPublic }) {
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const isFromMobile = useMobileCheckHook();
  const { width } = useWindowSize();

  const isFromAffiliate =
    isFromPublic ||
    (theme === VIDEO_GUEST_BOOK && !store.get('onetimeoffer') && attributes?.['partner-name']);

  function onDismissOffer(e) {
    store.set('onetimeoffer', true);
    postPrivateEventDetails('celebration_one_time_offer_dismised', {
      'subject-type': 'Celebration',
      'subject-id': id,
    });
    onDismiss(e);
  }

  if (showSecondPopup) {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        textAlign="center"
        sx={{
          mt: '-40px',
          zIndex: isFromAffiliate ? 3 : 1,
          position: 'relative',
          // border-radius: 20px;
          background: 'linear-gradient(180deg, #000048 0%, #010101 100%)',
          boxShadow: '0px 6px 10px 4px rgba(0, 0, 0, 0.15)',
        }}
      >
        <Flex flexDirection="column" alignItems="center">
          <Text
            bg="#2C00A8"
            sx={{
              width: '100%',
              color: 'white',
              fontStyle: 'italic',
              fontWeight: 700,
              fontSize: ['20px', '22px'],
              lineHeight: '130%',
              // py: ['40px', '65px'],
              textAlign: 'center',
              p: '28px',
              zIndex: 1,
            }}
            mb={3}
          >
            Are you sure you want to decline this special one time only offer?
          </Text>
          <ConfettiWrapper>
            <Text
              sx={{
                fontWeight: 700,
                fontSize: ['24px', '36px'],
                lineHeight: '140%',
                mt: ['10px', '19px'],
                textAlign: 'center',
                mx: '36px',
                zIndex: 1,
              }}
              color="#fff"
            >
              You can get the bundle for
              <Text as="span" color="#13C283">
                {' '}
                {extractDollarValueFromString(attributes?.cta)}
              </Text>{' '}
              today which is a
              <Text as="span"> {extractDollarValueFromString(attributes?.['cta-short-text'])}</Text>{' '}
              value!
            </Text>
            <Flex flexDirection="column" alignItems="center">
              <Button
                sx={{ background: '#13C283' }}
                mt={3}
                fontSize="16px"
                zIndex={1}
                onClick={() => setShowSecondPopup(false)}
              >
                Hmm… let me reconsider
              </Button>
              <Button
                sx={{ bg: 'transparent', border: '2px solid white' }}
                width={330}
                py="5px"
                my={3}
                fontSize="16px"
                color="#fff"
                onClick={onDismissOffer}
              >
                No, I’ll pass on the one time offer
              </Button>
            </Flex>
          </ConfettiWrapper>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      textAlign="center"
      sx={{ zIndex: isFromAffiliate ? 3 : 1, position: 'relative' }}
    >
      <Image
        src={attributes?.[isFromMobile && width < 640 ? 'hero2-url' : 'hero-url']}
        height={['100vh', '850px']}
        mt={isFromPublic ? undefined : '-40px'}
        width={['100vw', '100vw', isFromMobile ? '100vw' : '100%']}
      />
      <Box
        sx={{
          zIndex: 1,
          height: [`90vh`, 'max-content'],
          overflowY: 'auto',
          position: 'absolute',
        }}
      >
        <Flex
          justifyContent="center"
          alignItems="end"
          height={[isFromAffiliate ? '25%' : '15%', 'max-content']}
          mt={isFromAffiliate ? [0, '200px'] : [0, '250px']}
          textAlign="center"
          fontSize="45px"
        >
          {isFromAffiliate ? '🥳' : ''}
        </Flex>
        <Text
          fontSize={['30px', '45px']}
          letterSpacing={calculateLetterSpacing(45, -0.99)}
          textAlign="center"
          color="#fff"
          maxWidth={[390, 430]}
          m="auto"
          dangerouslySetInnerHTML={{
            __html: isFromAffiliate
              ? attributes?.['partner-discount-description']
              : attributes?.['small-title'],
          }}
        />
        {!isFromAffiliate && (
          <Text
            sx={{
              color: 'rgba(255, 255, 255, 0.80)',
              fontSize: '18px',
              letterSpacing: '0.396px',
              lineHeight: '130%',
              maxWidth: '590px',
              m: 'auto',
            }}
          >
            Receive contributions from today until 3 months after your event. Share/downoload for 12
            months after.
          </Text>
        )}
        <Box
          m="auto"
          mt={4}
          sx={{
            width: ['90vw', '756px'],
            overflow: 'auto',
          }}
        >
          <Image src={Reviews} width={['1255px']} maxWidth="none" />
        </Box>
        <Flex
          mt="32px"
          alignItems="center"
          justifyContent="center"
          flexDirection={['column', 'row']}
        >
          <Text
            fontSize="20px"
            letterSpacing={calculateLetterSpacing(20, -0.44)}
            textAlign="center"
            color="#fff"
            dangerouslySetInnerHTML={{ __html: attributes?.['small-text'] }}
          />
          {isFromAffiliate && <WhatsIncluded id={id} />}
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <Button
            variant="special"
            sx={{ background: isFromAffiliate && '#13C283' }}
            mb={3}
            onClick={() => {
              postPrivateEventDetails('celebration_tapped_continue_on_plan_page', {
                context: {
                  plan_stripe_identifier: attributes?.['stripe-identifier'],
                },
                'subject-type': 'Celebration',
                'subject-id': id,
              });
              if (isFromAffiliate)
                onClick(`&memberPartnerDiscountId=${attributes?.['member-partner-discount-id']}`);
              else onClick('');
            }}
            width={330}
            mt={3}
          >
            Continue
          </Button>
          {isFromAffiliate && (
            <Button
              sx={{ bg: 'transparent', border: '2px solid white' }}
              width={330}
              py="5px"
              mb={3}
              fontSize="16px"
              color="#fff"
              onClick={() => setShowSecondPopup(true)}
            >
              I&apos;ll pass on this one-time offer and might purchase at regular price later.
            </Button>
          )}
        </Flex>
        {isFromAffiliate ? (
          <Text
            sx={{
              color: 'rgba(255, 255, 255, 0.80)',
              fontSize: ['18px', '15px'],
              letterSpacing: '0.396px',
              lineHeight: '130%',
              mx: 2,
              mb: 3,
            }}
          >
            Receive contributions until 3 months post-event. Video editing, sharing, and downloading
            available for one year.
          </Text>
        ) : (
          <WhatsIncluded id={id} />
        )}
      </Box>
    </Flex>
  );
}

function Paywall({ id, plans, celebration, currency, onDismiss, isFromPublic }) {
  const { push } = useRouter();
  return (
    <Box>
      {plans.map(
        ({ attributes }) =>
          attributes.platforms.toLowerCase().includes('stripe') && (
            <PriceBox
              isFromPublic={isFromPublic}
              key={attributes.id}
              theme={celebration?.theme}
              attributes={attributes}
              id={id}
              onClick={(parameter) => {
                const url = `/dashboard/${id}/payment/${attributes.id}?redirect=${window.location.href}${parameter}`;
                sendTrackingEvent(`member_visit`, {
                  context: {
                    url_text: attributes?.cta,
                    url,
                  },
                });
                push(url);
              }}
              currency={currency}
              onDismiss={onDismiss}
            />
          )
      )}
    </Box>
  );
}

export default Paywall;
