import React from 'react';
import { FiGift } from 'react-icons/fi';
import { useCelebrationInRoute } from '@/hooks/celebration';
// eslint-disable-next-line import/no-cycle
import { useCurrentBuzzCode } from '@/components/BuzzCodeBox';
import Banner from '../app/Banner';

function BuzzCodeBanner() {
  const celebration = useCelebrationInRoute();
  const currentCode = useCurrentBuzzCode();

  if (currentCode && celebration?.['will-paywall'])
    return (
      <Banner
        icon={FiGift}
        bg="successBg"
        color="success"
        heading="Buzz Code Enabled"
        text={currentCode.humanize}
        mb={5}
        size="small"
      />
    );

  return false;
}

export default BuzzCodeBanner;
