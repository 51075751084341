import React from 'react';
import { Text } from 'rebass';
import AnimatedDialog from '@/components/app/AnimatedDialog';
import DashboardPrices from '@/components/CelebrationDashboard/DashboardPrices';
import { useMobileCheckHook } from '@/hooks/celebration';
import { cssHandling } from '@/components/Printables/ReusableComponents';

function PaymentModal({
  onDismiss,
  isOpen,
  id,
  celebrationDetail,
  dontShowOneTimeOffer,
  individualClip,
  isFromPublic = false,
}) {
  const isFromMobile = useMobileCheckHook();
  return (
    <AnimatedDialog
      contentProps={{ width: '100vw', maxWidth: 880, mt: [2, 0] }}
      contentCSS={isFromMobile && cssHandling()}
      isOpen={isOpen}
      // isFromPayment={dontShowOneTimeOffer === false}
      // hideAutoDismiss
      onDismiss={onDismiss}
    >
      {individualClip ? (
        <Text fontWeight="400" fontSize="25px" lineHeight="130%" textAlign="center" py={6} px={3}>
          Download will be enabled after the Celebration is purchased. If you are the Producer, log
          in at{' '}
          <Text
            onClick={() => window.open('https://app.celebrate.buzz', '_blank')}
            sx={{ cursor: 'pointer', textDecorationLine: 'underline' }}
            as="span"
            fontWeight="bold"
          >
            https://app.celebrate.buzz
          </Text>{' '}
          to purchase.
        </Text>
      ) : (
        <DashboardPrices
          dontShowOneTimeOffer={dontShowOneTimeOffer}
          id={id}
          onDismiss={onDismiss}
          celebrationDetail={celebrationDetail}
          isFromPublic={isFromPublic}
        />
      )}
    </AnimatedDialog>
  );
}

export default PaymentModal;
