import React from 'react';
import { Text, Heading } from 'rebass';
import styled from '@emotion/styled';

export function StepHeading({ children, ...props }) {
  return (
    <Text color="purples.7" lineHeight="34px" fontSize="22px" textAlign="center" {...props}>
      {children}
    </Text>
  );
}

export function SubTitleHeading({ children, ...props }) {
  return (
    <Heading
      className="font-alta"
      color="#000"
      lineHeight="53px"
      fontSize="42px"
      textAlign="center"
      sx={{ textTransform: 'uppercase' }}
      mb="15px"
      {...props}
    >
      {children}
    </Heading>
  );
}

export const mobileModalCSS = () => `border-radius:0px; 
     overflow-y: auto !important;
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    padding-top:10px`;

export const ButtonStyles = {
  bg: 'purples.6',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
  letterSpacing: '0.01em',
  textTransform: 'uppercase',
  px: 4,
  py: 1,
};

export const Wrapper = styled.div`
  padding: 16px;
  padding-left: 0px;
  border-radius: 6px;
  margin-bottom: 16px;
  width: 100%;
`;

export const labelSx = {
  color: 'black',
  fontSize: ['18px', '32px'],
  lineHeight: '120%',
  fontWeight: '400 !important',
  textAlign: 'center',
};

export function cssHandling(isFromMobile, background = '#FDFDFD') {
  if (isFromMobile)
    return `border-radius:0px; 
     overflow-y: auto !important;
    position: fixed;
    height:  100%;
    width: 100%;
    max-width: 100%;
    left: 0;
    top: 0;
    padding-top:10px;
    margin-top:0px;
    background: ${background};`;
  return 'align-items: center; max-width: 800px;';
}
