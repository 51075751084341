/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Button, Image } from 'rebass';
import { useRouter } from 'next/router';
import store from 'store';
import ConfettiWrapper from '@/components/ConfettiWrapper';
import wave from '@/public/Images/NewDesignImages/wave.png';
import OneTimeOffer from '@/public/Images/NewDesignImages/BundleOfferMain.png';
import OneTimeOfferSide from '@/public/Images/NewDesignImages/BundleOfferSide.png';
import { useMobileCheckHook } from '@/hooks/celebration';
import OutlineButton from '@/components/app/OutlineButton';
import AnimatedDialog from '@/components/app/AnimatedDialog';
import { extractDollarValueFromString, sendTrackingEvent } from '@/lib/helper';
import PaymentPageVideoGuestBook from '@/components/Printables/payment/index';
import { CELEBRATE_BRIDAL_SHOWER } from '@/lib/constants';
import { postPrivateEventDetails } from '@/lib/api';
import { cssHandling } from '@/components/Printables/ReusableComponents';

function SecondPopup({ onClick, isBundle, onDismissOffer, cta, ctaShortText, ctaLongText }) {
  if (isBundle)
    return (
      <Flex flexDirection="column" alignItems="center">
        <Text
          bg="#6369EF"
          sx={{
            width: '100%',
            color: 'white',
            fontStyle: 'italic',
            fontWeight: 700,
            fontSize: ['18px', '27px'],
            lineHeight: '130%',
            // py: ['40px', '65px'],
            textAlign: 'center',
            p: '28px',
            zIndex: 1,
          }}
          mb={3}
        >
          Are you sure you want to decline this special one time only offer?
        </Text>
        <ConfettiWrapper>
          <Text
            sx={{
              fontWeight: 700,
              fontSize: ['24px', '36px'],
              lineHeight: '140%',
              mt: ['10px', '19px'],
              textAlign: 'center',
              mx: '36px',
              zIndex: 1,
            }}
            color="rgba(80, 80, 80, 0.8)"
          >
            You can get the bundle for
            <Text as="span" color="#13C283">
              {' '}
              {extractDollarValueFromString(cta)}
            </Text>{' '}
            today which is a<Text as="span"> {extractDollarValueFromString(ctaShortText)}</Text>{' '}
            value!
          </Text>
        </ConfettiWrapper>
        <Button variant="special" mb="19px" fontSize="16px" zIndex={1} onClick={onClick}>
          Hmm… let me reconsider
        </Button>
        <OutlineButton
          width="100%"
          maxHeight={43}
          borderRadius="9px"
          buttonProps={{ maxWidth: '273px', zIndex: 1 }}
          textSx={{ fontSize: '16px', lineHeight: '20.8px', letterSpacing: '-2.2%' }}
          onClick={onDismissOffer}
        >
          No, I’ll pass on the one time offer.
        </OutlineButton>
      </Flex>
    );

  return (
    <Flex flexDirection="column" alignItems="center">
      <Text
        bg="#6369EF"
        sx={{
          width: '100%',
          color: 'white',
          fontStyle: 'italic',
          fontWeight: 700,
          fontSize: ['18px', '27px'],
          lineHeight: '130%',
          // py: ['40px', '65px'],
          textAlign: 'center',
          p: '28px',
          zIndex: 1,
        }}
        mb={3}
      >
        Are you sure you want to decline this special one time only offer?
      </Text>
      <ConfettiWrapper>
        <Text
          sx={{
            fontSize: ['24px', '36px'],
            mt: ['10px', '20px'],
            mx: '36px',
            zIndex: 1,
          }}
          color="rgba(80, 80, 80, 0.8)"
          dangerouslySetInnerHTML={{ __html: ctaLongText }}
        />
      </ConfettiWrapper>
      <Button variant="special" mb="19px" fontSize="16px" zIndex={1} onClick={onClick}>
        Hmm… let me reconsider
      </Button>
      <OutlineButton
        width="100%"
        maxHeight={43}
        borderRadius="9px"
        buttonProps={{ maxWidth: '273px', zIndex: 1 }}
        textSx={{ fontSize: '16px', lineHeight: '20.8px', letterSpacing: '-2.2%' }}
        onClick={onDismissOffer}
      >
        No, I’ll pass on the one time offer.
      </OutlineButton>
    </Flex>
  );
}

function MainPopup({
  onClick,
  isBundle,
  setShowLearnMore,
  smallText,
  ctaShortText,
  description,
  heroUrl,
  hero2Url,
  cta,
  stripe,
  ctaClick,
}) {
  if (isBundle)
    return (
      <Box>
        <Flex flexDirection="column" alignItems="center">
          <Text
            sx={{
              fontStyle: 'italic',
              fontWeight: 700,
              fontSize: ['18px', '27px'],
              lineHeight: '130%',
              mt: ['40px', '65px'],
              textAlign: 'center',
              mx: '36px',
            }}
          >
            {description}
          </Text>
          <Text
            sx={{
              fontSize: ['12px', '18px'],
              lineHeight: '140%',
              mt: ['10px', '19px'],
              textAlign: 'center',
              mx: '36px',
            }}
            dangerouslySetInnerHTML={{ __html: smallText }}
            color="rgba(80, 80, 80, 0.8)"
          />
          <Flex my={4} flexDirection="column" justifyContent="center" alignItems="center">
            <Image src={OneTimeOffer} maxWidth={['304px', '333px']} />
            <Image my="auto" src={OneTimeOfferSide} maxWidth="108px" mt="-17px" />
          </Flex>
          <Text
            sx={{
              letterSpacing: '-1.1%',
              lineHeight: '27px',
              fontSize: '18px',
              textTransform: 'uppercase',
            }}
          >
            <Text
              as="span"
              color="#7B33FB"
              fontWeight={700}
              onClick={() => setShowLearnMore(true)}
              sx={{ textDecorationLine: 'underline', cursor: 'pointer' }}
            >
              Learn More
            </Text>
            &nbsp;about the bundle
          </Text>
          <Text
            sx={{
              fontWeight: 700,
              fontSize: ['20px', '30px'],
              lineHeight: '120%',
              my: ['10px', '20px'],
              textAlign: 'center',
            }}
            color="#13C283"
          >
            {ctaShortText}
          </Text>
          <Button variant="special" mb="19px" onClick={ctaClick}>
            {cta}
          </Button>
          <OutlineButton
            width="100%"
            maxHeight={43}
            borderRadius="9px"
            buttonProps={{ maxWidth: '270px' }}
            onClick={onClick}
          >
            Decline one time offer
          </OutlineButton>
        </Flex>
        <Text
          textAlign="center"
          sx={{
            color: '#8E8E8E',
            fontSize: ['12px', '14px'],
            fontWeight: 700,
            lineHeight: '140%',
            mb: '20px',
          }}
        >
          All prices in{' '}
          <Text as="span" sx={{ textTransform: 'uppercase' }}>
            {' '}
            {stripe?.currency}
          </Text>
        </Text>
      </Box>
    );

  return (
    <Flex
      justifyContent="space-between"
      flexDirection={['column', 'row']}
      sx={{ position: 'relative' }}
    >
      <Flex
        width={['100%', '40%']}
        flexDirection="column"
        alignItems="flex-start"
        pr={1}
        pl={[1, '36px']}
      >
        <Image
          src="https://stamped.io/inc/image/badges.png?apikey=pubkey-29q5Pd4mQvjs699wmK05x99aJw9kKH&sId=239092&type=badge2&label1=&label2="
          width="200px"
          mt={2}
        />
        <Text
          sx={{
            fontSize: ['30px', '55px'],
            mt: 2,
            textAlign: 'left',
          }}
          dangerouslySetInnerHTML={{ __html: smallText }}
        />
        <Text
          sx={{
            fontWeight: 700,
            fontSize: ['20px', '30px'],
            lineHeight: '120%',
            my: ['10px', '20px'],
            textAlign: 'center',
          }}
          color="#13C283"
        >
          {ctaShortText}
        </Text>
        <Button
          variant="special"
          m="auto"
          mb="19px"
          width="100%"
          maxWidth={['80%', '270px']}
          onClick={ctaClick}
        >
          {cta}
        </Button>
        <OutlineButton
          width="100%"
          maxHeight={43}
          borderRadius="9px"
          mb={2}
          buttonProps={{ maxWidth: ['80%', '270px'], mx: 'auto' }}
          onClick={onClick}
        >
          Decline one time offer
        </OutlineButton>
      </Flex>
      <Flex
        width={['100%', '40%']}
        sx={{ position: 'relative' }}
        my={[0, 4]}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          sx={{
            display: ['none !important', 'flex !important'],
            background: '#7B33FB',
            boxShadow: '0px 6px 10px 4px rgba(0, 0, 0, 0.15)',
            width: '30px',
            position: 'absolute',
            bottom: 0,
            /* left: -15px, */
            right: 'calc(100% - 30px)',
            top: '-150px',
            zIndex: 1,
          }}
        />
        <Image src={wave} sx={{ display: ['block', 'none'], zIndex: 1, mb: '-10%' }} />
        <Image
          my="auto"
          src={hero2Url}
          sx={{
            position: ['relative', 'absolute'],
            right: ['unset', 0],
            bottom: ['unset', 0],
            top: ['unset', '-75px'],
          }}
          maxWidth={['100%', '100%', '100%']}
          width="100%"
          height={['378px', '600px', '600px']}
        />
        <Image
          src={heroUrl}
          sx={{
            position: 'absolute',
            right: ['unset', '72%'],
            bottom: [0, '-32px'],
            zIndex: 2,
          }}
          maxWidth={['100%', '370px', '370px']}
          height={['318px', '370px', '370px']}
        />
      </Flex>
    </Flex>
  );
}

function RenderOneTimeOffer({ id, plans, onDismiss }) {
  const { push } = useRouter();

  const [showOneTimeOffer, setShowOneTimeOffer] = useState(!store.get('onetimeoffer'));
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [showLearnMore, setShowLearnMore] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(null);

  const isFromMobile = useMobileCheckHook();
  function onDismissOffer(e) {
    setShowOneTimeOffer(false);
    store.set('onetimeoffer', true);
    postPrivateEventDetails('celebration_one_time_offer_dismised', {
      'subject-type': 'Celebration',
      'subject-id': id,
    });
    onDismiss(e);
  }

  useEffect(() => {
    const fetchWebsiteContent = async () => {
      try {
        const response = await fetch(`/api/renderpage?url=${CELEBRATE_BRIDAL_SHOWER}`); // Use your proxy route
        if (response.ok) {
          const websiteContent = await response.text();
          setIframeSrc(`data:text/html,${encodeURIComponent(websiteContent)}`);
        }
      } catch (error) {
        console.error('Error fetching website content:', error);
      }
    };

    fetchWebsiteContent();
  }, []);

  if (plans.length === 0) return null;

  if (selectedPlanId)
    return (
      <PaymentPageVideoGuestBook
        isOpen={!!selectedPlanId}
        onDismiss={() => setSelectedPlanId(null)}
        planId={selectedPlanId}
      />
    );

  const isBundle = plans.find(
    (op) =>
      op?.attributes?.['stripe-identifier'] === 'one-video-guest-book-celebration-usd-free-bundle'
  );
  return (
    <AnimatedDialog
      contentProps={{ width: '100vw', maxWidth: 880, mt: [2, 0] }}
      isOpen={showOneTimeOffer}
      hideAutoDismiss
    >
      <AnimatedDialog
        isOpen={showLearnMore}
        contentProps={{ width: '100vw', maxWidth: 922, mt: 2 }}
        contentCSS={isFromMobile && cssHandling()}
        onDismiss={() => setShowLearnMore(false)}
      >
        <iframe
          src={iframeSrc}
          title="Embedded Website"
          width="100%"
          height="800"
          style={{ border: 'none', marginTop: '8px' }}
        />
      </AnimatedDialog>
      <Box>
        {!showSecondPopup && (
          <Text
            sx={
              isBundle
                ? {
                    borderRadius: '32px',
                    py: [1, 2],
                    px: ['31px', '51px'],
                    fontStyle: 700,
                    fontSize: ['10.56px', '15.84px'],
                    lineHeight: '120%',
                    color: '#fff',
                    position: 'absolute',
                    left: '50%',
                    transform: 'translate(-50%, 0%)',
                    top: ['unset', '45px'],
                    mt: [0, '-45px', '-24px'],
                    bg: '#13C283',
                  }
                : {
                    py: [1, 2],
                    pl: ['0px', '69px'],
                    fontStyle: 700,
                    fontSize: ['18px', '20px'],
                    lineHeight: '120%',
                    fontWeight: 700,
                    letterSpacing: '-0.44px',
                    width: '100%',
                    mt: ['15px', '34px'],
                    textAlign: ['center', 'left'],
                    color: '#F2C94C',
                    position: 'relative',
                    zIndex: 1,
                    background: '#7B33FB',
                    boxShadow: '0px 6px 10px 4px rgba(0, 0, 0, 0.15)',
                  }
            }
          >
            <b>*</b>ONE TIME ONLY OFFER<b>*</b>
          </Text>
        )}
        {plans.map(
          ({
            id: planId,
            attributes: {
              description,
              'small-text': smallText,
              'cta-short-text': ctaShortText,
              'cta-long-text': ctaLongText,
              'hero-url': heroUrl,
              'hero2-url': hero2Url,
              cta,
              stripe,
            },
          }) => (
            <Box>
              {showSecondPopup ? (
                <SecondPopup
                  onClick={() => setShowSecondPopup(false)}
                  isBundle={isBundle}
                  onDismissOffer={onDismissOffer}
                  ctaLongText={ctaLongText}
                  cta={cta}
                  ctaShortText={ctaShortText}
                />
              ) : (
                <MainPopup
                  onClick={() => setShowSecondPopup(true)}
                  isBundle={isBundle}
                  setShowLearnMore={setShowLearnMore}
                  smallText={smallText}
                  ctaShortText={ctaShortText}
                  description={description}
                  heroUrl={heroUrl}
                  hero2Url={hero2Url}
                  cta={cta}
                  stripe={stripe}
                  ctaClick={() => {
                    const url = `/dashboard/${id}/payment/${planId}`;
                    postPrivateEventDetails('celebration_tapped_pay', {
                      'subject-type': 'Celebration',
                      'subject-id': id,
                    });
                    sendTrackingEvent(`member_visit`, {
                      context: {
                        url_text: 'One Time Offer',
                        url,
                      },
                    });
                    push(url);
                  }}
                />
              )}
            </Box>
          )
        )}
      </Box>
    </AnimatedDialog>
  );
}

export default RenderOneTimeOffer;
