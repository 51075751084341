import React from 'react';
import { Box, Flex, Image, Text } from 'rebass';
import useImage from '@/hooks/image';
import { useCelebrationInRoute } from '@/hooks/celebration';
import { useEntity } from '@/lib/entities';
import ScanPreview1 from '@/public/Images/VideoGuestBook/ScanPreview1.png';
import ScanPreview2 from '@/public/Images/VideoGuestBook/ScanPreview2.png';
import ScanPreview3 from '@/public/Images/VideoGuestBook/ScanPreview3.png';
import ScanArrow from '@/public/Images/VideoGuestBook/ScanArrow.png';
import OnboardSection from '../OnboardSection';
import AnimatedDialog from '../app/AnimatedDialog';

import { StepHeading, SubTitleHeading } from './ReusableComponents';

function LeftImagesSection() {
  return (
    <Box>
      <Flex>
        <Box sx={{ position: 'relative', mr: '-75px' }}>
          <Image src={ScanPreview3} />
        </Box>
        <Box sx={{ position: 'relative', mr: '-75px' }}>
          <Image src={ScanPreview1} />
        </Box>
      </Flex>
      <Box
        sx={{
          mt: '-130px',
          maxWidth: '150px',
          ml: '160px',
          position: 'absolute',
        }}
      >
        <Image src={ScanPreview2} />
      </Box>
    </Box>
  );
}

function MiddleImagesSection() {
  const celebration = useCelebrationInRoute();
  const celebrationInvitation = useEntity(celebration?.['celebration-invitation']);
  const QRCode = useImage({ id: celebrationInvitation?.['qr-code']?.id, size: 2 });

  return (
    <Box
      sx={{
        position: 'relative',
        background: 'linear-gradient(90deg, #EC2669 4.92%, #A949AB 47.81%, #686BEB 92.53%)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '38px',
      }}
      p={2}
      height="max-content"
      maxWidth="30%"
      ml={8}
    >
      <Text
        className="dm-sans"
        fontWeight={700}
        fontSize={24}
        lineHeight="34px"
        sx={{ textTransform: 'uppercase', color: 'white', textAlign: 'center' }}
      >
        Scan Me
      </Text>
      <Image src={QRCode} sx={{ borderRadius: '38px' }} />
      <Text
        className="dm-sans"
        fontSize={16}
        color="white"
        lineHeight="20px"
        textAlign="center"
        mt={2}
      >
        Scan the QR code to experience what your guests will see!
      </Text>
    </Box>
  );
}

function PrintableScanPreviewModal({ isOpen, onDismiss }) {
  return (
    <AnimatedDialog contentProps={{ width: '90vw' }} isOpen={isOpen} onDismiss={onDismiss}>
      <Box m="auto">
        <OnboardSection
          subTitle={<SubTitleHeading>Try iT for yourself!</SubTitleHeading>}
          support={
            <StepHeading px={2}>
              Scan the QR Code below to see what your guests will see. You can even record a video
              yourself to try it out.
            </StepHeading>
          }
          m="auto"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <LeftImagesSection />
            <MiddleImagesSection />
            <Box maxWidth="25%">
              <Image src={ScanArrow} />
            </Box>
          </Flex>
        </OnboardSection>
      </Box>
    </AnimatedDialog>
  );
}

export default PrintableScanPreviewModal;
