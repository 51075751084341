import React from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Text } from 'rebass';

function OutlineButton({
  onClick,
  children,
  borderRadius = '8px',
  mb = 2,
  mt,
  maxHeight,
  height = '56px',
  width = 'max-content',
  buttonProps,
  buttonSx = {},
  textSx = {},
  bg = 'white',
  backgroundImage = 'linear-gradient(90deg, #3866F4 0%, #7B33FB 48.96%, #BD00FF 100%)',
  background = 'linear-gradient(90deg, #DC2C77 0%, #7B5CDE 105.43%)',
}) {
  return (
    <Button
      bg="transparent"
      onClick={onClick}
      sx={{
        border: '1px solid transparent',
        backgroundImage,
        borderImageSlice: '1',
        borderRadius,
        p: '0px',
        width,
        ...buttonSx,
      }}
      mb={mb}
      mt={mt}
      {...buttonProps}
    >
      <Flex
        justifyContent="center"
        bg={bg}
        py={1}
        px={2}
        sx={{
          borderRadius,
          maxHeight,
          height,
        }}
      >
        <Text
          color="#7B33FB"
          sx={{
            background,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            alignSelf: 'center',
            fontSize: '20px',
            fontWeight: 400,
            lineHeight: '120%',
            ...textSx,
          }}
        >
          {children}
        </Text>
      </Flex>
    </Button>
  );
}

OutlineButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default OutlineButton;
