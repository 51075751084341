import React, { useState, useEffect } from 'react';
import { useWindowSize } from 'react-use';
import useSWR from 'swr';
import { Box, Text } from 'rebass';
// import { Switch } from '@rebass/forms';
import { useRouter } from 'next/router';
import store from 'store';
import { useCelebrationInRoute, useMobileCheckHook } from '@/hooks/celebration';
import { ax } from '@/lib/api';
import { useReceivePayload } from '@/lib/entities';
import Grid from '@/components/app/Grid';
import { DEFAULT_CURRENY, VIDEO_GUEST_BOOK } from '@/lib/constants';
import { checkIfIdExist, sendTrackingEvent } from '@/lib/helper';
import PriceBox from './PriceBox';
import BuzzCodeBox from '../BuzzCodeBox';
import AnimatedDialog from '../app/AnimatedDialog';
import Banner from '../app/Banner';
import PaymentPageVideoGuestBook from '../Printables/payment/index';
import RenderOneTimeOffer from './RenderOneTimeOffer';
import Paywall from './Paywall';

function DashboardPrices({
  id,
  onDismiss,
  celebrationDetail,
  dontShowOneTimeOffer = false,
  isFromPublic = false,
}) {
  const { push } = useRouter();
  const [code, setCode] = useState(null);
  const [selectCard, setSelectCard] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const { width } = useWindowSize();

  const isFromMobile = useMobileCheckHook();
  // const member = useCurrentMember();
  const celebration = useCelebrationInRoute() || celebrationDetail;
  const currency = DEFAULT_CURRENY;
  const [useBuzzCode, setUseBuzzCode] = useState(false);
  const [showOneTimeOffer, setShowOneTimeOffer] = useState(
    !dontShowOneTimeOffer &&
      !store.get('onetimeoffer') &&
      celebration?.theme === VIDEO_GUEST_BOOK &&
      !celebration?.paid
  );

  const receivePayload = useReceivePayload();
  const { mutate: revalidateMember } = useSWR(`/v3/me`);
  const { mutate: revalidateCelebration } = useSWR(
    checkIfIdExist(id) ? `/v3/celebrations/${id}?shallow=true` : null
  );
  // const currentCode = useCurrentBuzzCode();
  const [codeDetails, setCodeDetails] = useState(null);

  async function getUsersCouponDetails() {
    try {
      const { data, status } = await ax().get(`/v3/discounts`);
      if (status === 200 || status === 201) {
        const {
          data: { attributes },
        } = data;
        setCodeDetails({
          title: attributes[`coupon-title`],
          message: attributes[`coupon-details`],
          footer: attributes[`coupon-footer`] ? `*${attributes[`coupon-footer`]}` : '',
          isValid: attributes[`coupon-valid`],
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    setCode(null);
  }, [useBuzzCode]);

  useEffect(() => {
    getUsersCouponDetails();
  }, []);

  function getUrl() {
    if (isFromPublic) return `/v3/public/pricing_plans${window.location.search}`;
    if (id) {
      let param = `product_identifier=CELEBRATE&currency=${currency}`;
      if (celebration?.theme === VIDEO_GUEST_BOOK)
        if (dontShowOneTimeOffer) param = 'experiment=special_offer';
        else if (showOneTimeOffer) param = 'experiment=one_time_offer';

      return `/v3/celebrations/${id}/pricing_plans?${param}`;
    }
    return null;
  }

  const { data, mutate } = useSWR(getUrl());

  useEffect(() => {
    if (data?.data?.data?.length === 0 && showOneTimeOffer) {
      setShowOneTimeOffer(false);
      mutate();
    }
  }, [showOneTimeOffer, data?.data?.data?.length]);

  const getNoOfCards = (cards) => {
    if (isFromMobile) return 1;
    if (width < 800) return 2;
    if (cards?.length > 0) {
      return cards.filter(({ attributes }) => attributes.platforms.toLowerCase().includes('stripe'))
        .length;
    }
    return 3;
  };

  const handleSubmit = async () => {
    if (code) {
      // await POST buzz code
      await ax()
        .post(`/v3/member_affiliates/${code}`)
        .then((response) => {
          mutate();
          receivePayload(response);
        })
        .catch((err) => {
          mutate();
          store.set(
            'sentryError',
            `${err.response?.data?.errors[0]?.detail} /DashboardPrices Component error occured while applying memeber_affiliate code`
          );
        });
    }
  };
  const isAffiliatePaywall =
    data?.data?.data?.filter(({ attributes }) => !!attributes['partner-name']).length > 0;

  if (selectedPlanId)
    return (
      <PaymentPageVideoGuestBook
        isOpen={!!selectedPlanId}
        onDismiss={() => setSelectedPlanId(null)}
        planId={selectedPlanId}
      />
    );

  if (
    !isFromPublic &&
    ((dontShowOneTimeOffer && !isAffiliatePaywall) || celebration?.theme !== VIDEO_GUEST_BOOK)
  )
    return (
      <>
        {data ? (
          <Box>
            {codeDetails && (codeDetails.title || codeDetails.message) && (
              <Banner
                bg={codeDetails.isValid ? 'successBg' : 'pinks.1'}
                color={codeDetails.isValid ? 'success' : 'pinks.5'}
                heading={`${codeDetails.isValid ? 'Save' : ''} ${codeDetails.title}`}
                text={codeDetails.message}
                subText={codeDetails.footer}
                mb={5}
                p="16px"
                size={isFromMobile ? 'xsmall' : 'small'}
              />
            )}

            <Grid
              gridTemplateColumns={`repeat(${getNoOfCards(data.data?.data)}, 1fr)`}
              p={[2, 4]}
              gridGap={4}
            >
              {data.data?.data.map(
                ({ attributes }) =>
                  attributes.platforms.toLowerCase().includes('stripe') && (
                    <PriceBox
                      celebrationId={id}
                      key={attributes.id}
                      theme={celebration?.theme}
                      price={attributes['price-in-dollars']}
                      title={attributes.description}
                      description={attributes['small-text']}
                      smallTitle={attributes['small-title']}
                      imageUrl={attributes['hero-url']}
                      onClick={() => {
                        const url = `/dashboard/${id}/payment/${attributes.id}?redirect=${window.location.href}`;
                        sendTrackingEvent(`member_visit`, {
                          context: {
                            url_text: attributes.cta,
                            url,
                          },
                        });
                        push(url);
                      }}
                      // onClick={() => setSelectedPlanId(attributes.id)}
                      ctaText={attributes.cta}
                      primary={attributes['cta-short-text'] === 'Most popular'}
                      badge={attributes['cta-short-text']}
                      currency={currency}
                      isSelected={selectCard === attributes.id}
                      onSelect={() => setSelectCard(attributes.id)}
                    />
                  )
              )}
            </Grid>
            <Text
              textAlign="center"
              sx={{
                color: '#8E8E8E',
                fontSize: ['12px', '14px'],
                fontWeight: 700,
                lineHeight: '140%',
                mb: '20px',
              }}
            >
              All prices in{' '}
              <Text as="span" sx={{ textTransform: 'uppercase' }}>
                {' '}
                usd
              </Text>
            </Text>
          </Box>
        ) : null}
        <AnimatedDialog
          contentProps={{ maxWidth: 500, p: isFromMobile ? 2 : 4, width: '90vw' }}
          // title="Upload Celebration Invite Video Modal"
          isOpen={useBuzzCode}
          onDismiss={() => {
            mutate();
            revalidateMember();
            revalidateCelebration();
            setCode(null);
            setUseBuzzCode(false);
          }}
        >
          <BuzzCodeBox
            value={code}
            isFromMobile={isFromMobile}
            onChange={setCode}
            onSuccess={handleSubmit}
          />
        </AnimatedDialog>
      </>
    );

  if (!isFromPublic && showOneTimeOffer && data?.data?.data?.length > 0 && !isAffiliatePaywall)
    return <RenderOneTimeOffer id={id} plans={data.data?.data} onDismiss={onDismiss} />;

  if (data?.data?.data?.length > 0)
    return (
      <Paywall
        isFromPublic={isFromPublic}
        celebration={celebration}
        id={id}
        onDismiss={onDismiss}
        plans={data.data?.data}
        currency={currency}
      />
    );
}

export default DashboardPrices;
