import React, { useState } from 'react';
import { Image, Flex, Box } from 'rebass';
import Image1 from '@/public/Images/NewDesignImages/confetti/image-1.png';
import Image2 from '@/public/Images/NewDesignImages/confetti/image-2.png';
import Image3 from '@/public/Images/NewDesignImages/confetti/image-3.png';
import Image4 from '@/public/Images/NewDesignImages/confetti/image-4.png';
import Image5 from '@/public/Images/NewDesignImages/confetti/image-5.png';
import Image6 from '@/public/Images/NewDesignImages/confetti/image-6.png';
import Image7 from '@/public/Images/NewDesignImages/confetti/image-7.png';
import Image8 from '@/public/Images/NewDesignImages/confetti/image-8.png';

function ImageWrapper({ src, sx }) {
  return (
    <Image
      src={src}
      sx={{
        position: 'absolute',
        width: '20px' /* Adjust the size of the images */,
        height: '20px' /* Adjust the size of the images */,
        objectFit: 'contain',
        ...sx,
      }}
    />
  );
}

function ConfettiWrapper({ children }) {
  const [textHeight, setTextHeight] = useState(0);
  return (
    <Flex
      sx={{
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ImageWrapper
        src={Image1}
        sx={{
          left: '10px',
        }}
      />
      <ImageWrapper
        src={Image2}
        sx={{
          left: '15%',
          marginTop: `-${textHeight + 15}px`,
        }}
      />
      <ImageWrapper
        src={Image3}
        sx={{
          left: '50%',
          marginTop: `-${textHeight + 30}px`,
        }}
      />
      <ImageWrapper
        src={Image4}
        sx={{
          left: '85%',
          marginTop: `-${textHeight + 15}px`,
        }}
      />
      <ImageWrapper
        src={Image5}
        sx={{
          right: '10px',
        }}
      />
      <ImageWrapper
        src={Image6}
        sx={{
          left: '85%',
          marginBottom: `-${textHeight - 15}px`,
        }}
      />
      <ImageWrapper
        src={Image7}
        sx={{
          left: '50%',
          marginBottom: `-${textHeight - 5}px`,
        }}
      />
      <ImageWrapper
        src={Image8}
        sx={{
          left: '15%',
          marginBottom: `-${textHeight - 15}px`,
        }}
      />
      <Box
        mb={4}
        width="100%"
        ref={(el) => {
          if (!el) return;
          setTextHeight(el.getBoundingClientRect().height);
        }}
      >
        {children}
      </Box>
    </Flex>
  );
}

ConfettiWrapper.propTypes = {};

export default ConfettiWrapper;
