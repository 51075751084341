import React from 'react';
import useSWR from 'swr';
import { FiCheckCircle } from 'react-icons/fi';
import { useRouter } from 'next/router';
import { Flex, Text, Button, Image } from 'rebass';
import vgbbackgroundconfetti from '@/public/Images/NewDesignImages/vgbbackgroundconfetti.svg';
import { useCelebration, useMobileCheckHook } from '@/hooks/celebration';
import { checkIfIdExist, sendTrackingEvent } from '@/lib/helper';
import { useCheckIfQnAIdea } from '@/components/CelebrationForm/hooks';
import { ButtonStyles } from '../ReusableComponents';

export default function PaymentPageVideoGuestBookSuccess() {
  const {
    push,
    query: { id },
  } = useRouter();
  const isFromMobile = useMobileCheckHook();
  const isQnA = useCheckIfQnAIdea();
  useSWR(checkIfIdExist(id) ? `/v3/celebrations/${id}?shallow=true` : null);
  const celebration = useCelebration(id);
  let celebrationTheme = 'Video Guestbook';
  if (celebration?.theme === 'normal') celebrationTheme = 'Classic';
  else if (celebration?.theme === 'question_and_answer' || isQnA) celebrationTheme = 'Q&A';

  return (
    <Flex
      p={isFromMobile ? 1 : 4}
      pt={0}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Text as={FiCheckCircle} size={100} color="#11AF34" mt={2} />
      <Text textAlign="center" fontSize="24px" fontWeight={700} lineHeight="120%" color="#505050">
        Your {celebrationTheme} purchase was successful!
      </Text>
      <Image src={vgbbackgroundconfetti} height="355" sx={{ mt: '-120px' }} />
      <Button
        sx={ButtonStyles}
        mt={3}
        width="max-content"
        alignSelf="end"
        onClick={() => {
          sendTrackingEvent(`member_visit`, {
            context: {
              url_text: 'Finish / back to home',
              url: `/`,
            },
          });
          push(`/`);
        }}
      >
        Finish / back to home
      </Button>
    </Flex>
  );
}
