import React, { useState } from 'react';
import { Text, Box, Heading, Flex } from 'rebass';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import CheckoutForm from '@/components/CheckoutForm';
import { useEntity } from '@/lib/entities';
import { useCelebrationPath } from '@/hooks/api';
import { DEFAULT_CURRENY } from '@/lib/constants';
import { postPrivateEventDetails } from '@/lib/api';
import { ButtonStyles, StepHeading, SubTitleHeading } from '../ReusableComponents';
import PaymentPageVideoGuestBookSuccess from './success';
import PrintableScanPreviewModal from '../PrintableScanPreviewModal';

const TextStyle = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '150%',
};
function PriceDetails({ name, value, bold }) {
  return (
    <Flex justifyContent="space-between" my={1}>
      <Text sx={TextStyle} fontWeight={bold ? 600 : 'normal'}>
        {name}
      </Text>
      <Text sx={TextStyle} fontWeight={bold ? 600 : 'normal'}>
        {value}
      </Text>
    </Flex>
  );
}

function RenderPayment({ parentPlanId }) {
  const {
    query: { id },
  } = useRouter();

  const Elements = dynamic(() => import('@stripe/react-stripe-js').then((mod) => mod.Elements), {
    ssr: false,
  });
  const loadStripe = dynamic(() => import('@stripe/stripe-js').then((mod) => mod.loadStripe), {
    ssr: false,
  });

  const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);

  const [onSuccess, setOnSuccess] = useState(false);
  const [isScanPreviewOpen, setScanPreviewOpen] = useState(false);
  const celebrationPath = useCelebrationPath();
  let planId = null;
  if (parentPlanId) {
    planId = parentPlanId;
  } else {
    const { data } = useSWR(
      !parentPlanId
        ? `${celebrationPath}/pricing_plans?product_identifier=CELEBRATE&currency=${DEFAULT_CURRENY}`
        : null
    );
    const oneVideoGuestBook = data?.data?.data.filter(({ attributes: { description } }) =>
      description.toLowerCase().includes('one video guest book')
    );
    planId = oneVideoGuestBook && oneVideoGuestBook.length > 0 ? oneVideoGuestBook[0].id : null;
  }
  useSWR(planId ? `/v3/pricing_plans/${planId}` : null);
  const plan = useEntity(planId ? { id: planId, type: 'pricing-plans' } : {});

  if (onSuccess) return <PaymentPageVideoGuestBookSuccess />;

  return (
    <Elements stripe={stripePromise}>
      <Box mx="20px" mb={3} p={parentPlanId ? 2 : 0}>
        <Heading
          variant="headings.h2"
          pb={1}
          mt={2}
          fontWeight={400}
          textAlign="center"
          color="black"
          fontSize={['18px', '27px']}
          lineHeight="130%"
        >
          Unlock all premium features
        </Heading>
        {!parentPlanId && (
          <Box maxWidth={540} m="auto">
            <SubTitleHeading maxWidth="540px" fontSize="35px" m="auto">
              You’re one step away from unlocking your printables!
            </SubTitleHeading>
            <StepHeading>Please enter your payment info.</StepHeading>
            {/* <Heading pb={2} color="purples.7">Your Purchase:</Heading> */}
            {plan && (
              <Box mb={4}>
                <Box
                  my={2}
                  sx={{
                    ':after': {
                      display: 'block',
                      width: '100%',
                      height: 1,
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                      margin: 'auto',
                      content: '" "',
                    },
                  }}
                >
                  <PriceDetails name={plan.name} value={`$${plan['price-in-dollars']}`} />
                  {plan['amount-of-discount'] && (
                    <PriceDetails
                      name={`${plan['discount-description']} Discount`}
                      value={`- ${plan['amount-of-discount']}`}
                    />
                  )}
                </Box>
                <PriceDetails
                  bold
                  name="Pretax total"
                  value={`${plan['discounted-price'] || `$${plan['price-in-dollars']}`}`}
                />
                <Text sx={TextStyle} fontSize="16px">
                  Sales tax will be determined by your country, state, or province
                </Text>
              </Box>
            )}
          </Box>
        )}
        <CheckoutForm
          id={planId}
          parentPlanId={parentPlanId}
          buttonStyles={{
            ...ButtonStyles,
            fontSize: ['10px', '14px'],
            px: 2,
          }}
          buttonTitle={
            parentPlanId ? 'Pay and unlock printables, videos, and more!' : 'PAY & GET PRINTABLES'
          }
          onSuccess={() => setOnSuccess(true)}
          renderRightText={
            <Box alignItems="center">
              <PrintableScanPreviewModal
                isOpen={isScanPreviewOpen}
                onDismiss={() => setScanPreviewOpen(false)}
              />
              {!parentPlanId && (
                <>
                  <Text
                    variant="outline"
                    color="purples.7"
                    fontSize={12}
                    p={2}
                    mr={[0, 0, 2]}
                    sx={{
                      border: '2px solid #3B3F85',
                      fontWeight: 600,
                      borderRadius: '4px',
                      textTransform: 'uppercase',
                      cursor: 'pointer',
                      textAlign: 'center',
                    }}
                    onClick={() => {
                      postPrivateEventDetails('celebration_tapped_see_example', {
                        'subject-type': 'Celebration',
                        'subject-id': planId || id,
                      });
                      setScanPreviewOpen(true);
                    }}
                  >
                    Try it for yourself
                  </Text>
                  <Text mr={2} mt={1} textAlign={['center', 'center', 'left']} color="#B8B6C7">
                    Experience what your guests will see
                  </Text>
                </>
              )}
            </Box>
          }
        />
      </Box>
    </Elements>
  );
}

export default RenderPayment;
