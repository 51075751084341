import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Image, Button, Box } from 'rebass';
import Badge from '@/components/app/Badge';
import OneTimeOffer from '@/public/Images/NewDesignImages/BundleOfferMain.png';
import OneTimeOfferSide from '@/public/Images/NewDesignImages/BundleOfferSide.png';
import { ReactComponent as OneCelebration } from '@/public/Images/NewDesignImages/message--text-single.svg';
import { ReactComponent as ThreePack } from '@/public/Images/NewDesignImages/message--text-triple.svg';
import { ReactComponent as MultipleVideoPlay } from '@/public/Images/NewDesignImages/multiple--video-play.svg';
import { ReactComponent as VideoPlay } from '@/public/Images/NewDesignImages/video-play.svg';
import { ReactComponent as VGBSingle } from '@/public/Images/NewDesignImages/vgb-single.svg';
import { CELEBRATE_BRIDAL_SHOWER, VIDEO_GUEST_BOOK } from '@/lib/constants';
import { useMobileCheckHook } from '@/hooks/celebration';
import { calculateLetterSpacing } from '@/lib/helper';
import { postPrivateEventDetails } from '@/lib/api';
import OutlineButton from '../app/OutlineButton';
import AnimatedDialog from '../app/AnimatedDialog';
import { cssHandling } from '../Printables/ReusableComponents';

function GetTitleTheme(title, theme) {
  const values = theme ? `${title}-${theme}` : title;
  switch (true) {
    case 'Single-the_newlywed_game':
    case 'Single-question_and_answer':
      return <VideoPlay />;
    case '3 Pack Bundle-the_newlywed_game':
    case '3 Pack Bundle-question_and_answer':
      return <MultipleVideoPlay />;
    case values.includes('Single'):
      return <OneCelebration />;
    case values.includes('3 Pack Bundle'):
      return <ThreePack />;
    case 'Digital Guestbook-video_guest_book':
      return <VGBSingle />;
    case "The Honeymooner's Bundle-video_guest_book":
      return (
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          <Image src={OneTimeOffer} maxWidth={['304px', '333px']} />
          <Image my="auto" src={OneTimeOfferSide} maxWidth="108px" mt="-17px" />
        </Flex>
      );
    default:
      break;
  }
}

function PriceBox({
  ctaText,
  title,
  description,
  isSelected,
  onClick,
  onSelect,
  price,
  badge,
  theme,
  imageUrl,
  smallTitle,
  celebrationId,
}) {
  const [iframeSrc, setIframeSrc] = useState(null);
  const [showLearnMore, setShowLearnMore] = useState(false);
  const isFromMobile = useMobileCheckHook();

  const splittedPrice = price.toString().split('.');
  const isSingle =
    title.toLowerCase().includes('single') ||
    (title.toLowerCase().includes('video guestbook') && !title.includes('+'));

  useEffect(() => {
    const fetchWebsiteContent = async () => {
      try {
        const response = await fetch(`/api/renderpage?url=${CELEBRATE_BRIDAL_SHOWER}`); // Use your proxy route
        if (response.ok) {
          const websiteContent = await response.text();
          setIframeSrc(`data:text/html,${encodeURIComponent(websiteContent)}`);
        }
      } catch (error) {
        console.error('Error fetching website content:', error);
      }
    };

    fetchWebsiteContent();
  }, []);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        borderRadius: 'small',
        background: isSelected
          ? 'linear-gradient(360deg, rgba(123, 51, 251, 0) 54.47%, rgba(123, 51, 251, 0.1) 96.4%)'
          : '#fff',
        border: `1px solid ${isSelected ? '#6369EF' : '#D9D9D9'}`,
      }}
      onClick={onSelect}
    >
      <AnimatedDialog
        isOpen={showLearnMore}
        contentProps={{ width: '100vw', maxWidth: 922, mt: 2 }}
        contentCSS={isFromMobile && cssHandling()}
        onDismiss={() => setShowLearnMore(false)}
      >
        <iframe
          src={iframeSrc}
          title="Embedded Website"
          width="100%"
          height="800"
          style={{ border: 'none', marginTop: '8px', marginBottom: '50px' }}
        />
      </AnimatedDialog>
      {badge && (
        <Badge
          bg="#13C283"
          color="#fff"
          sx={{
            position: 'absolute',
            mt: '-12px',
          }}
        >
          {badge}
        </Badge>
      )}
      <Image src={imageUrl} width="100%" />
      <Flex p={2} flexDirection="column" alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          {GetTitleTheme(title, theme)}
          <Text
            fontSize={['18px', '24px']}
            lineHeight="27px"
            letterSpacing={calculateLetterSpacing(18, -1.1)}
            textAlign="center"
            color="#13C283"
            fontStyle="italic"
          >
            {smallTitle}
          </Text>
        </Flex>
        <Text
          my={2}
          fontSize={['20px', '26px']}
          lineHeight="28px"
          letterSpacing={calculateLetterSpacing(18, -2.2)}
          textAlign="center"
          color="black"
        >
          {title}
        </Text>
        <Text
          fontSize={['18px', '24px']}
          lineHeight="23.4px"
          letterSpacing={calculateLetterSpacing(18, -2.2)}
          textAlign="center"
          color="#505050CC"
          dangerouslySetInnerHTML={{ __html: description }}
        />

        {/* {theme === VIDEO_GUEST_BOOK && title !== 'Digital Guestbook' && (
        // <Image
        //   my={3}
        //   src={OneTimeOffer}
        // // maxWidth={['304px', '470px']}
        // />
        <Flex my={4} flexDirection="column" justifyContent={"center"} alignItems="center">
          <Image src={OneTimeOffer} maxWidth={['304px', '333px']} />
          <Image my={"auto"} src={OneTimeOfferSide} maxWidth='108px' mt="-17px" />
        </Flex>
      )} */}
        {theme !== VIDEO_GUEST_BOOK && (
          <Text
            fontWeight={700}
            my={3}
            fontSize={['22px']}
            lineHeight="130%"
            textAlign="center"
            color="#000000"
          >
            ${splittedPrice[0]}
            <span className="decimal">.{splittedPrice[1]}</span>
            {!isSingle && ' total'}
          </Text>
        )}
        <Box my={3}>
          {isSingle ? (
            <OutlineButton
              onClick={onClick}
              buttonProps={{
                mx: 'auto',
                my: theme === VIDEO_GUEST_BOOK ? 3 : 0,
                width: '100%',
                maxWidth: 240,
              }}
            >
              {ctaText}
            </OutlineButton>
          ) : (
            <Button
              sx={{
                m: 'auto',
                width: '100%',
                maxWidth: 240,
              }}
              type="button"
              m="auto"
              variant="special"
              onClick={onClick}
              px={2}
            >
              {ctaText}
            </Button>
          )}
        </Box>
        {(title === "The Honeymooner's Bundle" ||
          title === 'The Video Guestbook + Bachelorette Bundle') && (
          <Text
            as="span"
            color="#7B33FB"
            fontWeight={700}
            onClick={() => {
              postPrivateEventDetails(`celebration_tapped_learn_more_special_offer`, {
                'subject-type': 'Celebration',
                'subject-id': celebrationId,
              });
              setShowLearnMore(true);
            }}
            sx={{ cursor: 'pointer' }}
            letterSpacing="-1.1%"
            fontSize={15}
          >
            Learn More
          </Text>
        )}
      </Flex>
    </Flex>
  );
}

PriceBox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  badge: PropTypes.string,
  ctaText: PropTypes.string,
};

PriceBox.defaultProps = {
  badge: '',
  ctaText: 'Purchase Celebration',
};

export default PriceBox;
